/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import $ from 'jquery';
window.$ = window.jQuery = $;

import 'admin-lte/plugins/select2/js/select2.full.min.js';

//Initialize Select2 Elements
$('.select2').select2()
$(".select2-flag-icon").select2({
    templateResult: function (option) {
        if (!option.id) { return option.text; }
		let ob = `<img src="/img/flags/${option.id}.svg" width="20" class="mr-1" /> ${option.text}`
		return ob;
    },
    templateSelection: function (option) {
        if (!option.id) { return option.text; }
		let ob = `<img src="/img/flags/${option.id}.svg" width="20" class="mr-1" /> ${option.text}`
		return ob;
    },
    escapeMarkup: function (m) {
        return m;
    }
});